
import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Meta from '../helpers/Meta'

import '../styles/Home.sass'


export default function IndexPage() {
	return <div id='HomePage' className='Page'>

		<Meta />

		<main>

			<p id='hpHero'>
				Discover, filter, sort, and compare hardware.
			</p>

			<p><em>A work in progress alpha version.</em></p>

			<div id='hmCats'>
				
				<Link to='/monitors/' className='hpCategory'>
					<StaticImage 
						src='../images/home/monitors.png' 
						alt='' 
						placeholder='tracedSVG'
						fit='contain'
						layout='constrained'
					/>
					Monitors		
				</Link>

			</div>

		</main>

		<footer id='hpFooter'>

			contact@hardware.compare
			
		</footer>

	</div>


}
